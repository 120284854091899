import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as s from './Section.module.scss';

export interface SectionProps {
  title: string;
}

const Section = ({ title, children }: PropsWithChildren<SectionProps>) => (
  <section className={s.root}>
    <h3 className={s.title}>
      <span>{title}</span>
      <span className={s.topLine} />
    </h3>

    {children}
  </section>
);

export default Section;
