import * as React from 'react';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import * as s from './TimelineItemBase.module.scss';

export interface TimelineItemBaseProps {
  date?: string;
  isFirst?: boolean;
  hideGraphic?: boolean;
}

const TimelineItemBase = ({ date, isFirst, hideGraphic, children }: PropsWithChildren<TimelineItemBaseProps>) => (
  <div className={s.root}>
    <div className={s.date}>{date}</div>
    <div className={classNames(s.graphic, { [s.graphicHidden]: hideGraphic })}>
      <div className={s.point} />
      <div className={classNames(s.line, { [s.lineFirst]: isFirst })} />
    </div>
    <div className={s.text}>{children}</div>
  </div>
);

export default TimelineItemBase;
