import * as React from 'react';
import { graphql } from 'gatsby';
import { I18nextContext, useTranslation } from 'gatsby-plugin-react-i18next';
import { Helmet } from 'react-helmet';
import { I18NextContext } from 'gatsby-plugin-react-i18next/dist/types';
import MainContent from './_components/MainContent/MainContent';
import * as s from './Resume.module.scss';
import Aside from './_components/Aside/Aside';

const ResumePage = () => {
  const { t } = useTranslation();
  const context = React.useContext<I18NextContext>(I18nextContext);

  return (
    <div className={s.root}>
      <Helmet>
        <title>{t('page.title')}</title>
        <meta name="description" content={t('page.description')} />
        <link rel="alternate" href="https://omarmuscatello.com/en/cv" hrefLang="en" />
        <link rel="alternate" href="https://omarmuscatello.com/it/cv" hrefLang="it" />
        <link rel="alternate" href="https://omarmuscatello.com/en/cv" hrefLang="x-default" />
        <html lang={context.language} />
      </Helmet>
      <Aside />
      <MainContent />
    </div>
  );
};

export default ResumePage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
