import * as React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import * as s from './MainContent.module.scss';
import Timeline from '../Timeline/Timeline';
import TimelineItemNotes from '../TimelineItemNotes/TimelineItemNotes';
import { linkedinProfile } from '../../../../data/urls';
import Section from '../Section/Section';
import Skills from '../Skills/Skills';
import skills from '../../../../data/skills';
import { TimelineItemProps } from '../TimelineItem/TimelineItem';

const MainContent = () => {
  const { t } = useTranslation();

  const experiences: TimelineItemProps[] = t('experiences.items', { returnObjects: true });
  const training: TimelineItemProps[] = t('training.items', { returnObjects: true });

  return (
    <main className={s.root}>
      <div className={s.content}>
        <Section title={t('experiences.title')}>
          <Timeline items={experiences}>
            <TimelineItemNotes
              notes={
                <>
                  <Trans>experiences.others</Trans>
                  <OutboundLink href={linkedinProfile} rel="noreferrer nofollow" target="_blank">
                    <Trans>experiences.othersLink</Trans>
                  </OutboundLink>
                  .
                </>
              }
            />
          </Timeline>
        </Section>

        <Section title={t('training.title')}>
          <Timeline items={training}>
            <TimelineItemNotes
              notes={
                <>
                  <Trans>training.others</Trans>
                  <OutboundLink href={linkedinProfile} rel="noreferrer nofollow" target="_blank">
                    <Trans>training.othersLink</Trans>
                  </OutboundLink>
                  .
                </>
              }
            />
          </Timeline>
        </Section>

        <Section title={t('skills.title')}>
          <Skills items={skills} />
          <p className={s.skillsText}>
            <Trans>skills.description</Trans>
          </p>
        </Section>

        <footer className={s.footer}>
          <Trans>personalDataAuth</Trans>
        </footer>
      </div>
    </main>
  );
};

export default MainContent;
