import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import * as s from './AsideTableRow.module.scss';

export interface AsideTableRowProps {
  label: string;
  value: string;
  link?: string;
  showBorder?: boolean;
}

const AsideTableRow = ({ label, value, link, showBorder }: AsideTableRowProps) => {
  const border = showBorder || showBorder == null ? <div className={s.border} /> : null;

  const valueElem = link ? (
    <OutboundLink href={link} rel="noreferrer nofollow">
      {value}
    </OutboundLink>
  ) : (
    value
  );

  return (
    <>
      <div className={s.field}>{label}</div>
      <div>{valueElem}</div>
      {border}
    </>
  );
};

export default AsideTableRow;
