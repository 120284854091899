import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as s from './Timeline.module.scss';
import TimelineItem, { TimelineItemProps } from '../TimelineItem/TimelineItem';

export interface TimelineProps {
  items: TimelineItemProps[];
}

const Timeline = ({ items, children }: PropsWithChildren<TimelineProps>) => {
  const lastItemIndex = items.length - 1;

  return (
    <div className={s.root}>
      {items.map((item, index) => (
        <TimelineItem key={item.title} {...item} isFirst={index === 0} isLast={index === lastItemIndex} />
      ))}
      {children}
    </div>
  );
};

export default Timeline;
