import * as React from 'react';
import imgProfileJpg from '../../../../images/profile.jpg';
import imgProfileWebp from '../../../../images/profile.webp';
import * as s from './AsideHeader.module.scss';

const AsideHeader = () => (
  <>
    <picture>
      <source srcSet={imgProfileWebp} type="image/webp" />
      <img className={s.img} src={imgProfileJpg} alt="Omar Muscatello" width={150} height={150} />
    </picture>

    <h1 className={s.name}>
      <span>Omar</span>
      <br />
      <span className={s.lastname}>Muscatello</span>
    </h1>

    <p className={s.role}>
      <span>Engineering Manager</span>
    </p>
  </>
);

export default AsideHeader;
