import * as React from 'react';
import * as s from './SkillItem.module.scss';

export interface SkillItemProps {
  label: string;
  level: number;
}

const SkillItem = ({ label, level }: SkillItemProps) => (
  <>
    <span>{label}</span>
    <div className={s.indicator}>
      <div className={s.level} style={{ width: `${level}%` }} />
    </div>
  </>
);

export default SkillItem;
