import * as React from 'react';
import classNames from 'classnames';
import { Trans } from 'gatsby-plugin-react-i18next';
import TimelineItemBase from '../TimelineItemBase/TimelineItemBase';
import * as s from './TimelineItem.module.scss';

export interface TimelineItemProps {
  isFirst?: boolean;
  isLast?: boolean;

  date: string;
  title: string;
  subtitle: string;
  description: string;
}

const TimelineItem = ({ title, subtitle, description, isLast, ...props }: TimelineItemProps) => (
  <TimelineItemBase date={props.date} isFirst={props.isFirst} hideGraphic={false}>
    <h4 className={s.title}>
      <Trans>{title}</Trans>
    </h4>
    <h5 className={s.subtitle}>
      <Trans>{subtitle}</Trans>
    </h5>
    <p className={classNames({ [s.descriptionLast]: isLast })}>
      <Trans>{description}</Trans>
    </p>
  </TimelineItemBase>
);

export default TimelineItem;
