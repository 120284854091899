import * as React from 'react';
import * as s from './Skills.module.scss';
import SkillItem, { SkillItemProps } from '../SkillItem/SkillItem';

export interface SkillsProps {
  items: SkillItemProps[];
}

const Skills = ({ items }: SkillsProps) => (
  <div className={s.root}>
    {items.map((item) => (
      <SkillItem key={item.label} {...item} />
    ))}
  </div>
);

export default Skills;
