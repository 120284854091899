import * as React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import * as s from './AsideSocial.module.scss';

import imgGitHub from '../../../../images/social/github.svg';
import imgLinkedIn from '../../../../images/social/linkedin.svg';
import imgStackOverflow from '../../../../images/social/stackoverflow.svg';
import { githubProfile, linkedinProfile, stackOverflowProfile } from '../../../../data/urls';

const AsideSocial = () => (
  <div className={s.root}>
    <OutboundLink className={s.social} href={linkedinProfile} target="_blank" rel="noreferrer nofollow">
      <img src={imgLinkedIn} alt="LinkedIn" height={32} width={32} />
    </OutboundLink>

    <OutboundLink className={s.social} href={githubProfile} target="_blank" rel="noreferrer nofollow">
      <img src={imgGitHub} alt="GitHub" height={32} width={32} />
    </OutboundLink>

    <OutboundLink className={s.social} href={stackOverflowProfile} target="_blank" rel="noreferrer nofollow">
      <img src={imgStackOverflow} alt="StackOverflow" height={32} width={32} />
    </OutboundLink>
  </div>
);

export default AsideSocial;
