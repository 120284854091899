import * as React from 'react';
import * as s from './AsideTable.module.scss';
import AsideTableRow, { AsideTableRowProps } from '../AsideTableRow/AsideTableRow';

export interface AsideTableProps {
  items: AsideTableRowProps[];
}

const AsideTable = ({ items }: AsideTableProps) => {
  const lastItemIndex = items.length - 1;

  return (
    <div className={s.table}>
      {items.map((item, index) => (
        <AsideTableRow
          key={item.value}
          label={item.label}
          value={item.value}
          link={item.link}
          showBorder={index < lastItemIndex}
        />
      ))}
    </div>
  );
};

export default AsideTable;
