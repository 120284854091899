const skills = [
  { label: '.NET (C#)', level: 100 },
  { label: 'Angular', level: 100 },
  { label: 'JavaScript / TypeScript', level: 100 },
  {
    label: 'React.js',
    level: 60,
  },
  {
    label: 'HTML / (S)CSS',
    level: 100,
  },
  { label: 'Vue', level: 30 },
  { label: 'Microsoft Azure', level: 80 },
  {
    label: 'DevOps',
    level: 90,
  },
];

export default skills;
