import * as React from 'react';
import * as s from './TimelineItemNotes.module.scss';
import TimelineItemBase from '../TimelineItemBase/TimelineItemBase';

export interface TimelineItemNotesProps {
  notes?: React.ReactElement;
}

const TimelineItemNotes = ({ notes }: TimelineItemNotesProps) => (
  <TimelineItemBase isFirst={false} hideGraphic>
    {notes && <p className={s.notes}>{notes}</p>}
  </TimelineItemBase>
);

export default TimelineItemNotes;
