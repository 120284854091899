import * as React from 'react';
import { PropsWithChildren } from 'react';
import * as s from './AsideSection.module.scss';

export interface AsideSectionProps {
  title: string;
}

const AsideSection = ({ title, children }: PropsWithChildren<AsideSectionProps>) => (
  <section>
    <h2 className={s.title}>{title}</h2>
    <div>{children}</div>
  </section>
);

export default AsideSection;
